/* background-color */

.bg-blue-light-a200 {
  background-color: #40c4ff;
}

.bg-cyan-5 {
  background-color: #00bcd4;
}

.bg-gray {
  background-color: gray;
}
.bg-gray-1 {
  background-color: #f5f5f5;
}
.bg-gray-2 {
  background-color: #eeeeee;
}

.bg-gray-3 {
  background-color: #e0e0e0;
}

.bg-gray-4 {
  background-color: #bdbdbd;
}

.bg-gray-5 {
  background-color: #9e9e9e;
}

.bg-gray-6 {
  background-color: #757575;
}

.bg-gray-7 {
  background-color: #616161;
}

.bg-gray-8 {
  background-color: #424242;
}

.bg-gray-9 {
  background-color: #212121;
}

.bg-none {
  background-color: none;
}

.bg-pink-9 {
  background-color: #880e4f;
}

.bg-purple-dark-a200 {
  background-color: #7c4dff;
}

.bg-purple-dark-a400 {
  background-color: #651fff;
}

.bg-red {
  background-color: red;
}

.bg-red-1 {
  background-color: #ffcdd2;
}
.bg-red-2 {
  background-color: #ef9a9a;
}
.bg-red-3 {
  background-color: #e57373;
}
.bg-red-4 {
  background-color: #ef5030;
}
.bg-red-5 {
  background-color: #f44336;
}
.bg-red-6 {
  background-color: #e53935;
}
.bg-red-7 {
  background-color: #d32f2f;
}
.bg-red-8 {
  background-color: #c52828;
}
.bg-red-9 {
  background-color: #b71c1c;
}

.bg-yellow {
  background-color: yellow;
}

.bg-yellow-a2 {
  background-color: #ffff00;
}
/* background-color end */

.bold {
  font-weight: bold;
}

.border-2 {
  border: 2px solid #188f00;
}

.border-3 {
  border: 3px solid #383838;
}

.input {
  margin: auto;
  background-color: antiquewhite;
}

.bg-blue {
  background-color: blue;
}

.bg-blue-light-1 {
  background-color: rgb(0, 183, 255);
}

.bg-dark-gray {
  background-color: rgb(54, 54, 54);
}

.fit-image {
  width: 100%;
  object-fit: cover;
  height: 180px;
}

.fill-height-80vh {
  height: 80vh;
}

/* margin */
.m--1 {
  margin: -1%;
}
.mt--1 {
  margin-top: -10px;
}
/* margin end */

/* opacity */
.opacity-1 {
  opacity: 0.1;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-9 {
  opacity: 0.9;
}

.opacity-10 {
  opacity: 1;
}
/* opacity end */

/* padding */
.p--1 {
  padding: -1%;
}

.p-y-10 {
  padding: 10% 0%;
}
/* padding end */
.pointer {
  cursor: pointer;
}

/* text color */
.text-blue {
  color: blue;
}

.text-black {
  color: black;
}

.text-gray {
  color: gray;
}

.text-gray-light-1 {
  color: rgb(216, 216, 216);
}

.text-red {
  color: red;
}

.text-purple-dark-a200 {
  color: #7c4dff;
}

.text-yellow {
  color: yellow;
}

.text-yellow-a400 {
  color: #ffea00;
}
/* text color end */
.font-size-1 {
  font-size: 0.1em;
}
.font-size-2 {
  font-size: 0.2em;
}

.font-size-3 {
  font-size: 0.3em;
}

.font-size-4 {
  font-size: 0.4em;
}

.font-size-5 {
  font-size: 0.5em;
}

.font-size-6 {
  font-size: 0.6em;
}

.font-size-7 {
  font-size: 0.7em;
}

.font-size-8 {
  font-size: 0.8em;
}
.font-size-9 {
  font-size: 0.9em;
}

.font-size-10 {
  font-size: 1em;
}

.font-size-11 {
  font-size: 1.1em;
}

.font-size-12 {
  font-size: 1.2em;
}
.font-size-13 {
  font-size: 1.3em;
}
.font-size-14 {
  font-size: 1.4em;
}
.font-size-15 {
  font-size: 1.5em;
}
.font-size-16 {
  font-size: 1.6em;
}
.font-size-17 {
  font-size: 1.7em;
}
.font-size-18 {
  font-size: 1.9em;
}
.font-size-19 {
  font-size: 1.9em;
}
.font-size-20 {
  font-size: 2em;
}
.font-size-21 {
  font-size: 2.1em;
}

.py-10 {
  padding: 10% 0%;
}

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.height-30vh {
  height: 50vh;
}

@import url("https://fonts.googleapis.com/css2?family=Allerta+Stencil&family=Work+Sans&display=swap");
.font-family-1 {
  font-family: "Allerta Stencil", sans-serif;
}

@import url("https://fonts.googleapis.com/css2?family=Share:wght@700&display=swap");
.font-family-2 {
  font-family: "Share", cursive;
}

@import url("https://fonts.googleapis.com/css2?family=Gugi&display=swap");
.font-family-3 {
  font-family: "Gugi", cursive;
}

@import url("https://fonts.googleapis.com/css2?family=Baloo+Tammudu+2:wght@600&display=swap");
.font-family-4 {
  font-family: "Baloo Tammudu 2", cursive;
}
