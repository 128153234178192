.background-image {
  background-image: url("./images/background-5.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #464646;
}

.background-image-yellow {
  background-image: url("./images/background-2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0b0a0a;
}

.background-image-gray {
  background-image: url("./images/background-4.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #464646;
}

.background-image-blue {
  background-image: url("./images/background-8.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #464646;
}

.bg-step {
  background-color: #0b0a0a;
}

.bg-step2 {
  background-color: #272424;
}
/* .nav-link {
  background-color: #343a40;
  border-bottom: 5px solid #343a40;
}

.nav-link:hover {
   background-color: grey; 
   border-bottom: 5px solid #ffd600;
} 

.dropdown-toggle {
   background-color: grey; 
   border-bottom: 0px;
} 
.dropdown-toggle:hover {
   background-color: grey; 
  border-bottom: 0px;
} */

.aaa {
  color: white !important;
  position: absolute;
}

.dropdown-menu {
  min-width: 7rem !important;
}

/* .nav-link:hover span {
  color: #ffd600;
} */

.dropdown-menu.show {
  position: absolute;
  z-index: 2;
}

.home-menu {
  color: white;
  padding: 10px 0px;
}

.home-menu-selected {
  color: black;
  background-color: #ffd600;
  padding: 10px 0px;
}

.home-menu:hover {
  color: black;
  background-color: #ffd600;
  cursor: pointer;
}

.bottom-menu {
  color: white;
  padding: 10px 0px;
  text-decoration: none !important;
  font-weight: bold;
}

.bottom-menu-selected {
  color: black;
  text-decoration: none !important;
  background-color: #ffd600;
  padding: 10px 0px;
  font-weight: bold;
}

.bottom-menu:hover,
.bottom-menu-selected:hover {
  color: black;
  background-color: #ffd600;
  cursor: pointer;
}

.text-orange {
  color: orange;
}

.btn-1 {
  color: black;
  background-color: orange;
  font-weight: bold;
}

.btn-1:hover,
.btn-1:active {
  border: 1.5px solid white !important;
  opacity: 0.6;
  color: black !important;
  background-color: orange !important;
  font-weight: bold;
}

.m-top {
  margin-top: -34px;
}

.m-top-2 {
  margin-top: -120px;
}

.nav-link {
  color: white !important;
}
